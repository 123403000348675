

<style scoped>
.log-entry {
  margin: 5px 0;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.log-entry .log {
  color: #000;
}

.log-entry .error {
  color: red;
}

.log-entry .warn {
  color: orange;
}

.log-entry .info {
  color: blue;
}
</style>
<script setup lang="ts">
import AppTemplate from "@/views/templates/AppTemplate.vue";
import PanelUi from "@/components/ui/PanelUi.vue";
import Multiselect from "vue-multiselect";
import {
  computed,
  ComputedRef,
  defineProps,
  onMounted,
  onUnmounted,
  ref,
  Ref,
  watch,
} from "vue";
import moment from "moment";
import { useStore } from "@/use/useStore";
import { IUser } from "@/store/modules/user/interfaces";
import { USER_STORE } from "@/store/modules/user/constants";
import SpinnerUi from "@/components/ui/SpinnerUi.vue";
import {
  PlayIcon,
  StopIcon,
  DownloadIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/vue/solid";
import { API } from "@/service/API";
import { apiErrorHandlers, formatSecToTime } from "@/service/utils";
import {
  RecordingResponse,
  RecordsResponse,
  TranscriptionsResponse,
  TranscriptionsRealTimeResponse,
  IRecord,
  IAudience,
  IAudienceSearch,
  RecordResponse,
  ITranscription,
  ITranscriptionRealTime
} from "@/interfaces/IAudience";
import { Status } from "@/enums/Audience";
import { router } from "@/router";
import { IWebexMeeting } from "@/interfaces/IWebex";
import { createVfm } from 'vue-final-modal';
import { useModal } from 'vue-final-modal'
import Modal from '@/views/pages/Modal.vue'
import axios from 'axios';

  


/* const props = defineProps({
  id: {
    type: String,
    required: false,
  },
}); */

const store = useStore();

const user: ComputedRef<IUser> = computed(
  () => store.getters[USER_STORE.GETTERS.LOGGED_USER]
);

const defaultMeeting: IWebexMeeting = {
  endTime: null,
  id: null,
  instanceId: null,
  meetingType: null,
  startTime: null,
  title: null,
  url: null,
};

const meeting: ComputedRef<IWebexMeeting> = computed(
  () => {const meetingFromStore = store.getters[USER_STORE.GETTERS.MEETING];
        return meetingFromStore || defaultMeeting
});

const court = computed(
  () => `${user.value.name} (${user.value.district} - ${user.value.court})`
);



let record = ref({
  process_number: "",
  audience: "",
  manualPart: "",
  part: "",
});


let loadingPlayer = ref(true);

let guestToken = ref("");

let isRecord = ref(false);
let processingRecording = ref(false);
let processingStop = ref(false);
const isEnableRecord = computed(() => {
  const enable = !isRecord.value && !processingRecording.value;
  console.log(`isEnableRecord: ${enable}`);
  return enable;
});

async function fetchMeetingUrl() {
  const data = JSON.stringify({
    id: meeting.value.id,
  });

  const config = {
    method: 'post',
    maxBodyLength: Infinity,
    url: 'https://api3.plataformareach.com.br/webhook/ac62e1de-a4f9-40b3-b386-356c7336961c',
    headers: { 
      'Content-Type': 'application/json'
    },
    data: data,
  };

  try {
    const response = await axios.request(config);
    if (response.data.success === true && response.data.meetingUrl) {
      return response.data.meetingUrl;
    } else {
      throw new Error('Falha ao obter meetingUrl.');
    }
  } catch (error) {
    console.error('Erro ao fazer a solicitação:', error);
    throw error;
  }
}

const randomId = generateRandomId(10); // Gera o ID aleatório de 10 caracteres

async function startRecord() {
  initializeWebex();
  isRecord.value = true; // Chama a função para inicializar o Webex após o carregamento do script
  processingRecording.value = true;
  console.log(user.value.email);
  console.log(meeting.value.id);

  await API.http
  .post(`recall/sendBot`, {
    id: randomId,
    meeting: meeting.value.id,
    userMail: user.value.email
  })
  .then((response) => {
      console.log(response.data);
      isRecord.value = true;
      processingRecording.value = false;
      startTimer();
  })
  .catch((error) => {
      console.error("Erro na API:", error);
      processingRecording.value = false; // Define como false em caso de erro
    });
    
}
function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

async function stopRecord() {
  await webex.meetings.unregister();
  await meetingSDK.leave();
  isRecord.value = false;
  console.log('Closing WebSocket connection...');
  websocket.close(1000, 'Closing connection'); // 1000 é o código de status para "Normal Closure"
  processingStop.value = false;
  stopTimer();
  /*
  console.log(user.value.email);
  console.log(meeting.value);
  await API.http
  .post(`recall/removeBot`, {
    id: user.value.email,
    meeting: meeting.value.id
  })
  .then((response) => {
      console.log(response.data);
      isRecord.value = false;
      processingStop.value = false;
      stopTimer();
  })
  .catch((error) => {
      console.error("Erro na API:", error);
      processingRecording.value = false; // Define como false em caso de erro
    });*/
}

const transcriptions: Ref<ITranscription[]> = ref([]);
const transcriptionsInTimeline = ref<ITranscription[]>([]);
const transcriptionsRealTime: Ref<ITranscriptionRealTime[]> = ref([]);

  async function getRecords() {
  try {
    const response = await API.http.get<TranscriptionsResponse>(`recall/getTranscriptions`);
    const responseData = response.data;

    // Verifique se 'list' está presente na resposta
    if (responseData.list) {
      // Mapeie os dados da lista de transcrições para a interface ITranscription
      const transcriptionsData: ITranscription[] = responseData.list.map((item) => ({
        id: item.id,
        email: item.email,
        meetingId: item.meetingId,
        created_at: item.created_at,
        duration: item.duration,
        // Mapeie os dados da transcrição para a interface TranscriptionData
        transcription: {
          frases: item.transcription.frases.map((frase) => ({
            inicio: frase.inicio,
            fim: frase.fim,
            texto: frase.texto,
          })),
          totalSegundos: item.transcription.totalSegundos,
        },
      }));

      // Atualize a referência 'transcriptions' com os dados mapeados
      transcriptions.value = transcriptionsData;
    } else {
      console.error("Lista de transcrições ausente na resposta.");
    }

    // Se você precisar acessar as informações de paginação, elas estão em 'responseData.pagination'
  } catch (error) {
    console.error("Erro ao obter transcrições:", error);
    apiErrorHandlers(error);
  }
}
async function getGuestToken() {
  //console.log(meeting.value.id);
  console.log("DOPSAAAAAAAAAA");
  await API.http  
    .post<TranscriptionsRealTimeResponse>(`recall/getGuestToken`, {
      meeting: meeting.value.id
    })
    .then((response) => {
      const responseData = response.data;
      console.log("RESPONSADATA", responseData);
      if (responseData.token) {
        console.log("asdads", responseData);
       guestToken.value = responseData.token;
       return responseData.token;

        
       
      }
    })
    .catch((error) => {
        console.error("Erro na API:", error);
    });
}

async function getTranscriptionsRealTime() {
  console.log(user.value.email);
  //console.log(meeting.value.id);
  
  await API.http
    .post<TranscriptionsRealTimeResponse>(`recall/getTranscriptionsRealTime`, {
      meeting: meeting.value.id
    })
    .then((response) => {
      const responseData = response.data;
      if (responseData.list) {
        // Mapeie os dados da lista de transcrições para a interface ITranscription
        const transcriptionsDataRealTime: ITranscriptionRealTime[] = responseData.list.map((item) => ({
          idrtTranscriptions: item.idrtTranscriptions, // Certifique-se de usar o nome correto do campo ID
          email: item.email,
          meetingId: item.meetingId,
          created_at: item.created_at,
          duration: item.duration,
          // Mapeie os dados da transcrição para a interface TranscriptionData
          transcription: {
              inicio: item.transcription.inicio,
              fim: item.transcription.fim,
              texto: item.transcription.texto,
            }
        }));
        
        transcriptionsRealTime.value = transcriptionsDataRealTime;
        console.log(transcriptionsDataRealTime);
      } // Este fechamento de chaves corresponde ao if
    })
    .catch((error) => {
        console.error("Erro na API:", error);
    });
}

var meetingSDK;


const { open } = useModal({
  component: Modal
});

const openTimeline = (transcription : ITranscription) => {
  try{
    transcriptionsInTimeline.value = transcriptions.value;
    console.log(transcriptionsInTimeline.value);
    const { open } = useModal({
      component: Modal,
      attrs: {
        transcription: transcription,
        title: 'Detalhes da Transcrição',
      },
    });

    open()
  }catch(err){
    console.log(err);
  }
  
};
var webex;
var websocket;
const audience: Ref<IAudience | undefined> = ref();
async function getAudience() {
  await API.http
    .get<IAudienceSearch>(`audiences/`)
    .then((response) => {
      audience.value = response.data.audience;
      if (audience.value?.status === Status.PUBLISHED) {
        router
          .push({
            name: "AudienceListRecords",
            params: {
              id: audience.value.id,
            },
          })
          .then();
      }

      audience.value?.participants.push({
        cod: 0,
        name: "Nova parte",
        description: "",
        type: "P",
      });

      record.value.process_number = audience.value.process_number;
      record.value.audience = audience.value.type;
    })
    .catch(apiErrorHandlers);
}

let startTime = ref();
let isTimerRunning = ref(false);
let timerInterval = ref();
let now = ref(moment());

function startTimer() {
  startTime.value = moment();
  isTimerRunning.value = true;

  timerInterval.value = setInterval(() => {
    now.value = moment();
  }, 1000);
}
function stopTimer() {
  isTimerRunning.value = false;
  clearInterval(timerInterval.value);
}
const getTimer = computed(() => {
  if (isTimerRunning.value) {
    let time = now.value.diff(startTime.value, "seconds");
    return time > 0 ? time : 0;
  }

  return 0;
});

function editRecord(record: IRecord) {
  record.new_manual_part = record.manual_part;
  record.onEdit = true;
}

function formatTime(time: number): string {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
  
    return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  }
  
  function formatDateTime(dateTime: string): string {
    // Lógica de formatação de data e hora conforme necessário
    return dateTime; // Aqui você pode usar o moment.js ou outra biblioteca para formatar a data
  }
async function saveRecord(record: IRecord) {
  if (record.new_manual_part.length) {
    record.onEdit = false;
    await API.http
      .put<RecordingResponse>(`audiences/records/${record.id}`, {
        part: record.new_manual_part,
      })
      .then(() => {
        getRecords();
      })
      .catch(apiErrorHandlers);
  }
}
const logs = ref<{ message: string; type: string }[]>([]);

async function generateLink(record: IRecord) {
  record.download_url = "generating";
  await API.http
    .get<RecordResponse>(
      `audiences/generate-link`
    )
    .then((response) => {
      record.download_url = response.data.record.download_url;
      getRecords();
    })
    .catch(apiErrorHandlers);
}
const config = ref({
  typebot: "bot-t-j-r-s-jud-cza5l98",
  apiHost: "https://bot2.plataformareach.com.br",
  theme: {
    button: { backgroundColor: "#5ac2ad" },
    chatWindow: { backgroundColor: "#fff" },
  },
  prefilledVariables:{
      meetingId: meeting.value.id
  }
});

const loadTypebotScript = (config) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.type = "module";
    script.src = 'https://cdn.jsdelivr.net/npm/@typebot.io/js@0.2.41/dist/web.js';
    script.async = true;
    script.onload = () => resolve(script);
    script.onerror = () => reject(new Error('Script loading failed.'));
    document.head.appendChild(script);
  }).then(() => {
    if (window.Typebot) {
      window.Typebot.initBubble(config);
    }
  });
};
const loadScript = (src: string) => {
  return new Promise<void>((resolve, reject) => {
    // Verifique se o script já foi carregado
    if (document.querySelector(`script[src="${src}"]`)) {
      resolve();
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.async = true;
    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Falha ao carregar o script: ${src}`));
    document.head.appendChild(script);
  });
};
function handleAudioStream(stream) {
  const socket = new WebSocket('wss://meet-stream.onni.ai:6060/out');
  websocket = socket;
  socket.onopen = () => {
    console.log('WebSocket connection opened');

    const initialMessage = {
      type: 'start',
      bot_id: randomId,
      timestamp: new Date().toISOString(),
      additionalInfo: 'Informações adicionais'
    };

    socket.send(JSON.stringify(initialMessage));
    console.log('Initial JSON message sent:', initialMessage);

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    const mediaStreamSource = audioContext.createMediaStreamSource(stream);

    const scriptProcessor = audioContext.createScriptProcessor(4096, 1, 1);  // Reduzido o tamanho do buffer
    mediaStreamSource.connect(scriptProcessor);
    scriptProcessor.connect(audioContext.destination);

    scriptProcessor.onaudioprocess = (audioProcessingEvent) => {
      const inputBuffer = audioProcessingEvent.inputBuffer.getChannelData(0);
      const resampledBuffer = resampleAudioBuffer(inputBuffer, audioContext.sampleRate, 16000);

      // Log para verificar os dados do buffer de áudio
      console.log('Raw audio data:', inputBuffer);

      if (socket.readyState === WebSocket.OPEN) {
        const int16Array = new Int16Array(resampledBuffer.length);
        for (let i = 0; i < resampledBuffer.length; i++) {
          int16Array[i] = Math.min(1, Math.max(-1, resampledBuffer[i])) * 0x7FFF;
        }
        socket.send(int16Array);
        console.log('Raw audio data sent to WebSocket');
      } else {
        console.warn('WebSocket not open. ReadyState:', socket.readyState);
      }
    };
  };

  socket.onerror = (error) => {
    console.error('WebSocket error:', error);
  };

  socket.onclose = async () => {
    await webex.meetings.unregister();
    console.log('WebSocket connection closed');
  };
}

function sendInitialMessage(socket) {
  const initialMessage = {
    type: 'start',
    bot_id: 'teste',
    timestamp: new Date().toISOString(),
    additionalInfo: 'Informações adicionais'
  };

  socket.send(JSON.stringify(initialMessage));
  console.log('Initial JSON message sent:', initialMessage);
}

function processAudioData(audioProcessingEvent, originalSampleRate, socket) {
  const inputBuffer = audioProcessingEvent.inputBuffer.getChannelData(0);
  const resampledBuffer = resampleAudioBuffer(inputBuffer, originalSampleRate, 16000);

  if (socket.readyState === WebSocket.OPEN) {
    const int16Array = new Int16Array(resampledBuffer.length);
    for (let i = 0; i < resampledBuffer.length; i++) {
      int16Array[i] = Math.min(1, Math.max(-1, resampledBuffer[i])) * 0x7FFF;
    }
    socket.send(int16Array);
    console.log('Resampled audio data sent to WebSocket');
  }
}

function resampleAudioBuffer(inputBuffer, originalSampleRate, targetSampleRate) {
  const ratio = originalSampleRate / targetSampleRate;
  const outputLength = Math.ceil(inputBuffer.length / ratio);
  const resampledBuffer = new Float32Array(outputLength);

  for (let i = 0; i < outputLength; i++) {
    const sourceIndex = i * ratio;
    const intSourceIndex = Math.floor(sourceIndex);
    const fraction = sourceIndex - intSourceIndex;

    const nextIndex = intSourceIndex + 1 < inputBuffer.length ? intSourceIndex + 1 : intSourceIndex;
    resampledBuffer[i] = (1 - fraction) * inputBuffer[intSourceIndex] + fraction * inputBuffer[nextIndex];
  }

  return resampledBuffer;
}
const initializeWebex = async () => {
  if (window.Webex) {
    webex = window.Webex.init();
    console.log('Webex inicializado:', webex);


    webex.once('ready', async () => {
      try {
        console.log('Requesting access token...');

        const jwt = await getGuestToken();
        console.log("JWT AA", guestToken.value);
        await webex.authorization.requestAccessTokenFromJwt({
          jwt: guestToken.value
        });

        const meetingLink = await fetchMeetingUrl(); // substitua com o link da sua reunião

        console.log('Guest user authenticated successfully');
        

        await webex.meetings.register();

        await webex.meetings.create(meetingLink).then(async (meeting: any) => {
          console.log('Meeting object created successfully');

          meetingSDK = meeting;
          meetingSDK.on("media:ready", (media: any) => {
            console.log("MEDIA FOUND");
            console.log(media);

            // Manipulação de fluxo de mídia
            if (media.type === 'remoteAudio') {
            console.log('MediaStream:', media.stream);
              if (media.stream) {
                console.log('MediaStream:', media.stream);

                const audioTracks = media.stream.getAudioTracks();

                if (audioTracks.length > 0) {
                  console.log('Audio track found:', audioTracks[0]);
                  handleAudioStream(media.stream, webex);
                  const audioElement = document.createElement('audio');
                    audioElement.srcObject = media.stream;
                    audioElement.play()
                    audioElement.muted = true;
                    audioElement.style.display = 'none';
                } else {
                  console.log('No audio tracks found in the MediaStream');
                }
              } else {
                console.log('No MediaStream found in the media object');
              }           // Aqui você pode manipular o fluxo de áudio remoto
            } else if (media.type === 'localAudio') {
              console.log('Local audio stream ready');
              // Aqui você pode manipular o fluxo de áudio local
            }
          });

          meetingSDK.on("self:unlocked", () => {
            console.log('The app should be in the call now');
          });

          meetingSDK.on("error", async (error: any) => {

        console.error('Webex meeting error:', error);
        await webex.meetings.unregister();
        });

          const mediaOptions = {
            mediaOptions: {
              allowMediaInLobby: true,
              audioEnabled: true,
              shareAudioEnabled: true,
              receiveAudio: true,
              bundlePolicy: 'max-bundle'
            }
          };
          await meetingSDK.joinWithMedia(mediaOptions).then(async () => {
            console.log('Joined the meeting successfully');
          }).catch(async (error: any) => {
            console.error('Failed to join the meeting', error);
            await webex.meetings.unregister();
          });

        }).catch(async (error: any) => {
          console.error('Failed to create meeting', error);
          await webex.meetings.unregister();

        });
      } catch (error: any) {
        console.error('SDK authorization failed', error);
        await webex.meetings.unregister();
      }
    });
  } else {
    console.error("Webex não está disponível.");
  }
};



onMounted(async () => {  
  
  await loadTypebotScript(config.value);
  try {
    await loadScript('https://unpkg.com/webex@3.3.1/umd/webex.min.js');
    console.log('Script Webex carregado com sucesso.');
  } catch (error) {
    console.error(error);
  }
  console.log("teste",isEnableRecord.value);
  getRecords();
  if(meeting.value){
    getTranscriptionsRealTime();
  }


  const intervalId = setInterval(() => {
    if(isRecord.value == true){
      getTranscriptionsRealTime();
    } 
  }, 5000);
  
  onUnmounted(() => {
    clearInterval(intervalId);
  });

});

import { Standard } from '@typebot.io/nextjs'

</script>


<template>
  <app-template>
    <div
      class="w-container mx-auto max-w-7xl flex flex-col md:flex-row p-2 sm:pt-5 gap-4 md:px-5 md:gap-5"
    >
        <panel-ui
          :title="$t('audience.transcription_title')"
          class="bg-gray-100 w-full md:w-2/5"
        >
          <div class="-space-y-px flex flex-col gap-y-3">
            <div class="flex flex-row justify-end gap-5">
              <span
                v-if="isTimerRunning"
                class="group relative w-2/3 flex justify-end p-2"
              >
                <svg
                  class="h-5 w-5 float-left mr-2 mt-0.5 text-red-600 animate-pulse"
                  fill="currentColor"
                  viewBox="0 0 8 8"
                >
                  <circle cx="4" cy="4" r="3" />
                </svg>
                {{ formatSecToTime(getTimer) }}
              </span>
              <!-- Botão de Iniciar Gravação -->
              <button
                :class="isEnableRecord ? 'theme-bg-color hover:theme-bg-color-darker' : 'bg-gray-400 cursor-default'"
                @click="startRecord"
                :disabled="!isEnableRecord"
                class="group relative w-1/2 xl:w-1/3 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white"
              >
                <template v-if="processingRecording">
                  {{ $t("audience.form.processing") }}
                  <spinner-ui class="h-5 w-5 float-left ml-3 text-white" />
                </template>
                <template v-else>
                  {{ $t("audience.form.startRecording") }}
                </template>
              </button>

              <!-- Botão de Parar Gravação -->
              <button
                :class="isRecord && !processingStop ? 'theme-bg-color hover:theme-bg-color-darker' : 'bg-gray-400 cursor-default'"
                @click="stopRecord"
                :disabled="!isRecord"
                class="group relative w-1/2 flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white"
              >
                <template v-if="processingStop">
                  {{ $t("audience.form.processing") }}
                  <spinner-ui class="h-5 w-5 float-left ml-3 text-white" />
                </template>
                <template v-else>
                  <stop-icon class="h-5 w-5 text-white mr-3" />
                  {{ $t("audience.form.stopRecording") }}
                </template>
              </button>
            </div>
            <div class="transcription-real-time bg-gray-100 rounded-md shadow p-4 w-96 h-500px overflow-y-auto" style="
    height: 300px;
    width: 585px;
">
          <h3 class="text-lg font-semibold mb-3">Transcrição em Tempo Real</h3>
          <div v-if="transcriptionsRealTime.length > 0" class="space-y-4">
            <div
              v-for="transcription in transcriptionsRealTime"
              :key="transcription.idrtTranscriptions"
              class="bg-white rounded-md shadow p-4"
            >
              <p class="text-gray-700 mb-2">
                <span class="font-semibold">Texto:</span> {{ transcription.transcription.texto }}
              </p>
              <p class="text-gray-600">
                <span class="font-semibold">Início:</span> {{ formatTime(transcription.transcription.inicio) }} |
                <span class="font-semibold">Fim:</span> {{ formatTime(transcription.transcription.fim) }}
              </p>
              <p class="text-gray-600">
                <span class="font-semibold">Criado em:</span> {{ formatDateTime(transcription.created_at) }}
              </p>
            </div>
          </div>
          <p v-else class="text-gray-600">Nenhuma transcrição em tempo real disponível.</p>
        </div>
          </div>
          



        </panel-ui>

        
    </div>

    <div
      class="w-container mx-auto max-w-7xl flex flex-col md:flex-row p-2 sm:pt-4 gap-4 md:px-5 md:gap-5 mb-3"
    >
      <panel-ui
        class="bg-gray-200 w-full mb-10"
        contentClass="p-3 overflow-auto"
        title="Transcricoes"
      >
        <table
          class="sm:table w-full flex flex-row sm:flex-col flex-nowrap text-sm"
        >
          <thead class="text-gray-600 sm:w-full">
            <tr
              v-for="(transcription, idx) in transcriptions"
              :key="`trTranscription${transcription.id}`"
              class="bg-gray-50 flex flex-col flex-nowrap rounded-l-lg sm:rounded-none mb-2 sm:mb-0"
              :class="idx === 0 ? 'sm:table-row' : 'sm:hidden'"
            >
              <th
                class="table-cell p-2 text-left border-b-2 border-t sm:border-b-0 border-color-gray-300 rounded-lt-lg sm:rounded-none"
              >
                {{ $t("audience.table.start_at") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.duration") }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ "Download" }}
              </th>
              <th class="table-cell p-2 text-left border-b-2 sm:border-b-0">
                {{ $t("audience.table.status") }}
              </th>
              <th
                class="table-cell p-2 text-left border-b sm:border-b-0 rounded-bl-lg sm:rounded-none"
              >
                &nbsp;
              </th>
            </tr>
          </thead>
          <tbody
            v-if="transcriptions !== undefined && transcriptions.length > 0"
            class="flex-1 sm:flex-none w-full text-gray-700"
          >
            <tr
              v-for="(transcription, idx) in transcriptions"
              :key="`transcription${transcription.id}`"
              :class="idx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              class="flex flex-col flex-nowrap sm:table-row mb-2 sm:mb-0 rounded-r-lg sm:rounded-none"
            >
              <td
                class="border-gray-200 border p-2 sm:border-none rounded-tr-lg sm:rounded-none"
              >
                {{ moment(transcription.created_at).format("HH:mm") }}
              </td>
              <td class="border-gray-200 border p-2 sm:border-none">
                {{ transcription.duration }}
              </td>
              <td
                class="border-gray-200 border p-2 sm:border-none flex flex-row gap-3 rounded-br-lg sm:rounded-none"
              >
                <a
                  v-if="
                    transcription.transcription == null
                  "
                  :href="JSON.stringify(transcription.transcription)"
                  target="_blank"
                  class="theme-color cursor-pointer hover:theme-color-darker"
                >
                  <download-icon class="w-6 h-6" />
                </a>
                <a
                  v-if="transcription.transcription !== null"
                  @click="openTimeline(transcription)"
                  class="theme-color cursor-pointer hover:theme-color-darker text-center py-1"
                >
                  Abrir Transcricao
                </a>
                <span class="pt-1" v-if="transcription.meetingId === 'generating'">
                  <spinner-ui class="h-4 w-4" />
                </span>
              </td>
              <td class="border-gray-200 border p-2 sm:border-none">
                {{ "Finalizado" }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="transcriptions.length === 0">
            <tr>
              <td>
                <p
                  class="text-gray-700 text-sm text-center py-10 bg-gray-100 rounded-md"
                >
                  {{ $t("audience.noRecords") }}
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </panel-ui>
      
      
    </div>
  </app-template>
</template>
